import iconOne from '../../assets/service/1.png';
import iconTwo from '../../assets/service/2.png';
import iconThree from '../../assets/service/3.png';
import iconFour from '../../assets/service/4.png';

const ServicesData = [
    {
        'icon': iconOne,
        'title': 'Dentures',
        'description': 'Dentures are removable prosthetic devices that replace missing teeth, supported by the surrounding oral tissues.',
    },
    {
        'icon': iconTwo,
        'title': 'Implants',
        'description': 'A dental implant is a prosthetic device placed in the jawbone to support a replacement tooth, aiming to restore function and appearance.',
    },
    {
        'icon': iconThree,
        'title': 'Advanced Composite Restorations',
        'description': 'Advanced composite restorations use tooth-colored materials to fill cavities, restore teeth, or replace old fillings.',
    },
    {
        'icon': iconFour,
        'title': 'Root Canal Treatment',
        'description': 'Root canal treatment is a procedure to remove infected tooth pulp, eliminate infection, and protect the tooth from future infection.',
    }
]

export default ServicesData;