import imgOne from '../../assets/blogs/1.png';
import imgTwo from '../../assets/blogs/2.png';
import imgThree from '../../assets/blogs/3.png';

const BlogData = [
    {
        'img': imgOne,
        'url': 'new-technology-make-for-dental-operation',
        'title':'New Technology Make for Dental Operation',
        'description':'Advanced technology ensures precise diagnoses and effective treatments',
        'category': 'TECHNOLOGY'
    },
     {
        'img': imgTwo,
        'url':'regular-dental-care-make-your-smile-brighter',
        'title':'Regular Dental care make Your Smile Brighter',
        'description':'Regular dental care keeps your smile bright and healthy',
        'category': 'DENTAL'
    },
    {
        'img': imgThree,
        'url':'dental-hygiene-for-all-age-to-make-smile',
        'title':'Dental Hygiene for All Age to Make Smile',
        'description':'Dental hygiene for all ages to ensure a healthy, confident smile',
        'category': 'HYGIENE'
    }
];

export default BlogData;